const tenantENV = process.env.REACT_APP_TENANT_ID;
const clientENV = process.env.REACT_APP_CLIENT_ID


export const mdata = {
  tenant_id: getReportData(tenantENV),
  clientId: getReportData(clientENV)
}

function getReportData(es) {

  // console.log("hello thereeer", es)
  let pi = []
  let rs = ""
  let al = ""
  for (let i = es.length - 1; i >= 0; i--) {
    if (!isNaN(es.split("")[i])) {
      al += es.split("")[i]
    }
    else {
      break
    }
  }
  al = al.split('').sort().join('')
  let pl
  pl = es.slice(((es.length - 1) - al), es.length)
  let dp = ""
  for (let i = 0; i < pl.length; i++) {
    if (isNaN(pl.split("")[i])) {
      dp += pl.split("")[i]
    }
    else {
      break;
    }
  }
  dp = atob(dp)
  es = es.split(pl)[0]
  es = atob(es).split(',')
  let ea = es.slice(0, dp)
  pi = es.slice(dp, es.length)
  for (let i = 0; i < ea.length; i++) {
    if (ea[i].length > 3) {
      ea[i] = ea[i].split('').slice(0, 3).join('')
    }
  }
  pi.forEach((e) => {
    ea[e] = ea[e]?.slice(0, 2)
  })
  ea.forEach((e) => {
    rs += String.fromCharCode(e)
  })
  // console.log(rs)
  return rs;
}

export const modelId = {
  modelConfigurationID : "0FC66724-4344-4830-A34B-9AD9FE165836"
}

const nodeBaseURL = "https://Asami.azurefd.net/api";

const goBaseURL = "https://ava-eus-cdgen-prod-go-fnapp.azurewebsites.net/api";

const devConfig = {
  URL: {
    formLoadURL: `https://Asami.azurefd.net/api/fetchFormData?code=2hyZt91kTLFnjB9v8i6nyogtGmhjh8PGjoQOPETPq2jYThY5Onxf3w==`,
    formGetDataURL: `https://Asami.azurefd.net/api/loadFormData?code=N96wxTD5macP7EV30Ypem4LTgeW5iv/XWOD5bRf3kyEAna6fdD99gA==`,
    formPostDataURL: `https://Asami.azurefd.net/api/SubmitInfo?code=5RmgNHQyG75sBZiyMz4be60O/KUqstoaL2Pnz88jt88ogkrXeTyfvw==`,
    outputFileNameUpdate: `https://Asami.azurefd.net/api/outputFileUpdate?code=ecgMiBGvYWcMjlr47RswLDR5l1a1ARRS5APklpB5JPPypvkrYnmpcA==`,

    gridLoadURL: `https://Asami.azurefd.net/api/loadGridData?code=qgB8t9PcK8a2lG09igiJrell1tfkrmnABpySy2xGpT2fSU1ub81Piw==`,
    // deleteDataURL : Domain + 'grid/deleteStudent',
    filterLoadURL:
      "https://Asami.azurefd.net/api/fetchDynamicData?code=OjNTyldFzDdSA8wlK45rT06NkVtKFkzsWMc2nAyStNpCRo2yO0EhsQ==",
  },
  GO: {
    CodeGenURL:
      "https://ava-eus-cdgen-prod-go-fnapp.azurewebsites.net/api/codegengoapi?",
  },
  nodeBaseURL: "https://Asami.azurefd.net/api",
  goBaseURL: "https://ava-eus-cdgen-prod-go-fnapp.azurewebsites.net/api",
  URLs: {
    frontEnd:
      nodeBaseURL +
      "/frontEnd?code=M_e4LKOsUsABPNPRdrQrNJf-DAIVxvPUZ3w9Bm5WALYXAzFuiVX4kw==",

    service:
      nodeBaseURL +
      "/service?code=m46seDo8m5lDaSuJwXfjLJKCjtyUBRJ0ua5nUrruHXQYAzFuXzU5SQ==",
    DataBase:
      nodeBaseURL +
      "/DataBase?code=nq0g9JNnAS8NZ4nkuUEfO_eGCaFC9w5yegvbROAWIv9PAzFuLZ1EgA==",
    GenerateCode:
      nodeBaseURL +
      "/GenerateCode?code=YwQOE70v_zXOYobRnRtt4N01XSiNJ3pld9L6RdQ3xAXUAzFuO8mNwA==",
    viewGenerateCode:
      nodeBaseURL +
      "/viewGenerateCode?code=CRxATks-S1g15UeoavB3zppnFPJ-DdCTal90cs9zFMpPAzFuKPTZyQ==",
    getAllProjectDetails:
      nodeBaseURL +
      "/getAllProjectDetails?code=cFeY7fp5kAaewGKqE4t6cXhSvIggn5ZAti72BGCQgfRkAzFul3Zbug==",
    postFileUpload:
      nodeBaseURL +
      "/postFileUpload?code=4uQzBCNXODHaBm8eF0beeN5GT9tF4M7Rm7HG02GIQY8_AzFupLY1zg==",
    EntityName:
      nodeBaseURL +
      "/EntityName?code=Js-dQtlmJ8j1GhfF6x3YYgEoFowMPDsBJQM3izR7NR5nAzFuLNw5vQ==",
    EntityInfo:
      nodeBaseURL +
      "/EntityInfo?code=zpfd2ge_G2HzKai0mO7Ym6CSCilwfFEnNNhs6K16huEHAzFu2YBEzA==",
    organiseMethods:
      nodeBaseURL +
      "/organiseMethods?code=pesApBmAJwAkuWUAdlI__4elKrhvjQxFCCbZv2g6soTOAzFuHBiqaw==",
    apiDetails:
      nodeBaseURL +
      "/apiDetails?code=9GXcyS_6Da_SRZzNH0GUZSwnwqXZsk2g8xfGqJh_7AQuAzFuVpG-EQ==",
    gridProperties:
      nodeBaseURL +
      "/gridProperties?code=IzGuBBj59KuGOFOqdRTHC6KmCG7PbU3GcgoLVH1FdYZ2AzFuy4vnvA==",
    gridColumn:
      nodeBaseURL +
      "/gridColumn?code=qKBgR5D48YoGN7S4ohFf0485ys1XzQM-FyljPZ1R4SP2AzFu1jefPA==",
    formProps:
      nodeBaseURL +
      "/formProps?code=tB9EsIeIMyfaKePxT0cJaSc_-bR4485D4T96QRhNNvYeAzFuwpz56Q==",
    FormFilter:
      nodeBaseURL +
      "/FormFilter?code=MrDDyYcr2U4ZCWJxYMBseZ3PDj_VrznFEv8Q0mTlbhYkAzFuufU4ow==",
    setStatus:
      nodeBaseURL +
      "/setStatus?code=k_E99nlRbeEa-Y3PVFvQKXYzuU1e9-UGFIu_ZAn7qCeSAzFuJGYNLg==",
    getFiledetails:
      nodeBaseURL +
      "/getFileDetails?code=MLdMWlwinCSy7ZT-cSSsLpstENGdGB_pkKPaczLbzVimAzFuL17gYw==",
    GenerateFEGridCode: goBaseURL + "/generatefegridcode",
    GenerateFEFormCode: goBaseURL + "/generatefeformcode",
    GenerateBECode: goBaseURL + "/generatebecode",
  },
 
  phaseAi: {
    // activeuser : "http://51.8.201.226/activeuser/activeuser",
    activeuser : "https://npapi.asami.ai/activeuser/activeuser",

    promptUsageFetch : "https://prodapi.asami.ai/promptUsageFetch/promptUsageFetch",

    fixedResponseFetch : "https://prodapi.asami.ai/fixedResponseFetch/fixedResponseFetch",

    getAsamiUsage : "https://prodapi.asami.ai/getAsamiUsage/getAsamiUsage",

    costAnalysis : "https://prodapi.asami.ai/costAnalysis/costAnalysis",
    // costAnalysis : "http://51.8.201.226/costAnalysis/costAnalysis",
    
    fetchProjectConfig : "https://prodapi.asami.ai/fetchProject/fetchProject",
    // fetchProject : "http://51.8.201.226/fetchProject/fetchProject",

    getStory : "https://prodapi.asami.ai/getStory/getStory",
    insertChatinProject:
    "https://prodapi.asami.ai/insertChatID/insertChatID",
    getTaskConfig : "https://prodapi.asami.ai/getTask/getTask",
    allProjects:"https://api.avalonpm.ai/allProjects",

    
    fetchTask:"https://api.avalonpm.ai/fetchTask",

    fetchIssues:"https://api.avalonpm.ai/fetchIssues",

    getIssuesConfig : "https://prodapi.asami.ai/getIssues/getIssues",

    functionalDescription: "https://prodapi.asami.ai/functionalDescription/functionalDescription",
    similarityCheck : "https://prodapi.asami.ai/similaritySearch/similaritySearch",

    pushToTFS : "https://prodapi.asami.ai/tfsPush/tfsPush",
    
    getBlobURL: "https://prodapi.asami.ai/getBlobURL/getBlobURL",
    d2Service : "https://prodapi.asami.ai/validate_and_replace/validate_and_replace",

    krokiService : "https://kroki.io/d2/svg",

    plantUMLKroki:"https://kroki.io/plantuml/svg",
    // getImageDocResposne: "https://prodapi.asami.ai/getImageVidDocResponse/getImageVidDocResponse",
    getImageDocResposne: "https://prodapi.asami.ai/getImageVidDocResponse/getImageVidDocResponse",

    insertModelConfig: "https://prodapi.asami.ai/insertModelConfig/insertModelConfig",

    configRoute : "https://prodapi.asami.ai/config/config",

    postTfsConfig: "https://prodapi.asami.ai/postTfsConfig/postTfsConfig",

    configureOrUpdate: "https://prodapi.asami.ai/configureOrUpdate/configureOrUpdate",
    

    getRepoandBranch : " https://prodapi.asami.ai/getRepoandBranch/getRepoandBranch",
    // getRepoandBranch : "http://51.8.201.226/getRepoandBranch/getRepoandBranch",

    getBindParams:
    "https://prodapi.asami.ai/getBindParams/getBindParams",

    insertGetFeedBack : "https://prodapi.asami.ai/insertGetFeedBack/insertGetFeedBack",

    promptGen : "https://prodapi.asami.ai/promptGen/promptGen",

    systemMessageGen : "https://prodapi.asami.ai/systemPrompt/systemPrompt",

    fetchPromptData : "https://prodapi.asami.ai/get_prompt/get_prompt",

    deletePrompt:
      "https://prodapi.asami.ai/delete_prompt/delete_prompt",
    getApprovalRequestData:
      "https://prodapi.asami.ai/getApprovalRequestData/getApprovalRequestData",
    CostCalculationNav:
    "https://prodapi.asami.ai/calculationNav/calculationNav",
    getChat:
      "https://prodapi.asami.ai/getChat/getChat",
    getChatHistory:
      "https://prodapi.asami.ai/getChatHistory/getChatHistory",
    getInputTypeAndTechStack:
      "https://prodapi.asami.ai/get_input_type_and_tech_stack/get_input_type_and_tech_stack",
    getNotifications:
      "https://prodapi.asami.ai/getNotifications/getNotifications",
   
    getPromptFieldJson:
      "https://prodapi.asami.ai/getPromptJson/getPromptJson",
    getTeamHistory:
      "https://prodapi.asami.ai/getTeamHistory/getTeamHistory",
      getPromptHistory:
      "https://prodapi.asami.ai/getPromptHistory/getPromptHistory",
    insertConnectionID:
      "https://prodapi.asami.ai/insertConnectionID/insertConnectionID",
    insertPrompt:
      "https://prodapi.asami.ai/insertPrompt/insertPrompt",
    postMessage:
      "https://prodapi.asami.ai/postMessage/postMessage",
    securityKeyCheck:
      "https://prodapi.asami.ai/securityKeyCheck/securityKeyCheck",
    talkToAI:
      "https://ava-eus-asami-nde-fcn-dev.azurewebsites.net/api/talkToAI?code=CQKMTIltJiswmFpRTg-mNZX58Qo1XZ88kXYv3NXSP7Q4AzFulFqCeg==",
    updateApproval: "https://prodapi.asami.ai/updateApproval/updateApproval",
    updateNotification: "https://prodapi.asami.ai/updateNotification/updateNotification",
    deleteConnectionID:
      "https://prodapi.asami.ai/deleteConnectionID/deleteConnectionID",
    insertErrorLogs:
      "https://prodapi.asami.ai/insertErrorLog/insertErrorLog",
    techstackCRUD:
      "https://prodapi.asami.ai/techstackcrud/techstackcrud",

    getPractice:
      "https://prodapi.asami.ai/getPractice/getPractice",

    insertModelConfiguration:
      "https://prodapi.asami.ai/insertModelConfig/insertModelConfig",
    getModelConfiguration:"https://prodapi.asami.ai/getModelConfiguration/getModelConfiguration",
    getAllModelConfig:
      "https://prodapi.asami.ai/getAllModelConfig/getAllModelConfig",
    deleteModelConfiguration:
      "https://prodapi.asami.ai/deleteModelConfiguration/deleteModelConfiguration",
    serpAPI:
      "https://prodapi.asami.ai/serpAPI/serpAPI",
    kbUpload:
      "https://prodapi.asami.ai/kbUpload/kbUpload",
    kbRetriever:
      "https://prodapi.asami.ai/kbRetriever/kbRetriever",
      downloadExcel:
      "https://prodapi.asami.ai/PseudocodeCSV/PseudocodeCSV",
    loadBalancer:
      "https://prodapi.asami.ai/LoadBalancer/LoadBalancer",
    inputEnhancer :"https://prodapi.asami.ai/inputEnhancer/inputEnhancer",
    stream_loadBalancer:"https://prodapi.asami.ai/stream-loadBalancer/stream-loadBalancer"
  },
  
  roleConfig: {
    LeadershipDL: "54e13cf7-521b-4ca5-a457-06a8208f0027",
    LeadershipGroup: "e4c9b230-277c-4072-996d-4d9ac617a549",
    LeadGroup: "91dcc9ba-87ab-4553-979e-fc1cb0537693",
    ScopeID: "api://2214b374-39be-4eec-9ca0-3c6bdac6e02a/CDGEN",
  },
  blobDetails: {
    storagesastoken:
      "?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2026-11-09T20:58:50Z&st=2022-05-11T12:58:50Z&spr=https,http&sig=ai14oVMQhEygy%2BlXtrHB3ZkTIlvAdi0ZIyeeT2in5VY%3D",
    containerName: `ava-cdegen-v2json-prod-con`,
    storageAccountName: "avaeuscdgenprodstgacc",
  },
  blobInputDetails: {
    storagesastoken:
      "sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2026-11-09T20:58:50Z&st=2022-05-11T12:58:50Z&spr=https,http&sig=ai14oVMQhEygy%2BlXtrHB3ZkTIlvAdi0ZIyeeT2in5VY%3D",
    containerName: `ava-cdegen-inpel-prod-con`,
    storageAccountName: "avaeuscdgenprodstgacc",
  },
  blobBasePath:
    "https://avaeuscdgenprodstgacc.blob.core.windows.net/ava-cdegen-zip-prod-con/phase2-zip/",
  blobInputFilePath:
    "https://avaeuscdgenprodstgacc.blob.core.windows.net/ava-cdegen-inpel-prod-con/",
  blobDownloadFilePath:
    "https://avaeuscdgenprodstgacc.blob.core.windows.net/ava-cdegen-zip-prod-con",
};

export default devConfig;


// const devNodeBaseURL = "https://np-asami.azurefd.net/api";

// const devGoBaseURL = "https://ava-eus-cdegen-np-go-fcn.azurewebsites.net/api";

// const tenantENV = process.env.REACT_APP_TENANT_ID;
// const clientENV = process.env.REACT_APP_CLIENT_ID


// export const mdata = {
//   tenant_id: getReportData(tenantENV),
//   clientId: getReportData(clientENV)
// }

// function getReportData(es) {
  
//   // console.log("hello thereeer", es)
//   let pi = []
//   let rs = ""
//   let al = ""
//   for (let i = es.length - 1; i >= 0; i--) {
//     if (!isNaN(es.split("")[i])) {
//       al += es.split("")[i]
//     }
//     else {
//       break
//     }
//   }
//   al = al.split('').sort().join('')
//   let pl
//   pl = es.slice(((es.length - 1) - al), es.length)
//   let dp = ""
//   for (let i = 0; i < pl.length; i++) {
//     if (isNaN(pl.split("")[i])) {
//       dp += pl.split("")[i]
//     }
//     else {
//       break;
//     }
//   }
//   dp = atob(dp)
//   es = es.split(pl)[0]
//   es = atob(es).split(',')
//   let ea = es.slice(0, dp)
//   pi = es.slice(dp, es.length)
//   for (let i = 0; i < ea.length; i++) {
//     if (ea[i].length > 3) {
//       ea[i] = ea[i].split('').slice(0, 3).join('')
//     }
//   }
//   pi.forEach((e) => {
//     ea[e] = ea[e]?.slice(0, 2)
//   })
//   ea.forEach((e) => {
//     rs += String.fromCharCode(e)
//   })
//   // console.log(rs)
//   return rs;
// }
// export const modelId = {
//   modelConfigurationID : "D9A4E4EE-DFA9-463F-B715-0013EAA70952"
// }

// const devConfig = {
//   URL: {
//     formLoadURL: `https://np-asami.azurefd.net/api/fetchFormData?code=MY0Xa4aQGa1esDP5UN4ptwnPR7Yi8dge8A8TqgfA4hWqzUs7qdA91A==`,
//     formGetDataURL: `https://np-asami.azurefd.net/api/loadFormData?code=kVgLjp0lJlDNYTfd5XdawvzLP7xBd9b5O6i/pDIwQ7mMt9XvvP5JRQ==`,
//     formPostDataURL: `https://np-asami.azurefd.net/api/SubmitInfo?code=vY8ihhRYPlOcKExTBlIXQT8pkSPUvvTC3DlnmsYWs/ymY5Z5qa1RMg==`,
//     outputFileNameUpdate: `https://np-asami.azurefd.net/api/outputFileUpdate?code=EHHDOnkigRyams40zrG5a83upa89UzZDtVSWl9pfCIfTsWYDLIRRew==`,

//     gridLoadURL: `https://np-asami.azurefd.net/api/loadGridData?code=GCaTd4nk7D0cWHg4FMdqnuCiyagdl12no2TpegI/WYfON37ZSc1Frw==`,
//     // deleteDataURL : Domain + 'grid/deleteStudent',
//     filterLoadURL:
//       "https://np-asami.azurefd.net/api/fetchDynamicData?code=CxeEDINyvG3nXU/lRjU5caM9JYGs6WuzmagWcH9KxYwPW100M6Iu1w==",
//   },

//   GO: {
//     CodeGenURL:
//       "https://ava-eus-cdegen-np-go-fcn.azurewebsites.net/api/codegengoapi?",
//   },
//   nodeBaseURL: "https://np-asami.azurefd.net/api",
//   goBaseURL: "https://ava-eus-cdegen-np-go-fcn.azurewebsites.net/api",
//   URLs: {
//     frontEnd:
//       devNodeBaseURL +
//       "/frontEnd?code=NIEUEbYM5Cy2u7aL5KibLiarDw/Aq/5p3Xs4C0pAXk4FfjbG52aDVg==",
//     service:
//       devNodeBaseURL +
//       "/service?code=S8jiAzFM2z4fl4pP/ya3MGaM6xZ5j23cgszheDvcSLaeuPuyp8z2Zw==",
//     DataBase:
//       devNodeBaseURL +
//       "/DataBase?code=PdpSLrfrtYFmvWfHEQWaPxDK1GMm6AWMcC8o6E31MPnKmIXSzwvj3A==",
//     GenerateCode:
//       devNodeBaseURL +
//       "/GenerateCode?code=TrrRKs8H6CS65Bt8G4YCUwy94bGffWElaxSMcK9m3DIrerGJFffQnA==",
//     viewGenerateCode:
//       devNodeBaseURL +
//       "/viewGenerateCode?code=NR1L9rL6GaqhFuT93kLvI8lakVDYwg73mphHALnRX37sjS2PahLZeg==",
//     getAllProjectDetails:
//       devNodeBaseURL +
//       "/getAllProjectDetails?code=h0qJvBPzBybpPMHmjyJa5Babc2nbZZaEcAmMBXtQmJGA/3AJTd4VXg==",
//     postFileUpload:
//       devNodeBaseURL +
//       "/postFileUpload?code=dVflIL1XbqTLh3YSinbaUJBKLkU0ihlEFJLKeadPFqtYCfb9UMhbAg==",
//     EntityName:
//       devNodeBaseURL +
//       "/EntityName?code=odwAKJb/kwye11LpcG6EBiPrkGyxRyTuafhdn8w39lYeH5kezEQPnA==",
//     EntityInfo:
//       devNodeBaseURL +
//       "/EntityInfo?code=qBdBhsJwEpNyovqBjISFZBISNpGEZdDZ0AlosV1MHt6gFYWPaaEN9w==",
//     organiseMethods:
//       devNodeBaseURL +
//       "/organiseMethods?code=EDe7MZXAQuaw6jpq1lVFiyBPzp0AdERr5MVch7BQRYer45LgWlHJ1A==",
//     apiDetails:
//       devNodeBaseURL +
//       "/apiDetails?code=KjUyrZD5yTkwaEaVUFPVsYPkISWKVg7k/P3SD0Zp/f92cbr5TsiMcA==",
//     gridProperties:
//       devNodeBaseURL +
//       "/gridProperties?code=wR48had6TWpDzkb7INDxgm4c55CQWoNspwvvpHrrN854Xoe6NL/WoA==",
//     gridColumn:
//       devNodeBaseURL +
//       "/gridColumn?code=dBbQjmP1DOtaRzd5vMn4wK84H0XAnNpLGxlyp2LX1wmYx32RUom2iw==",
//     formProps:
//       devNodeBaseURL +
//       "/formProps?code=tqNpVxLX0aQGbXgF2wq1zKdkipJO6pHak9Fh9GdyVI4MGMnvsDduaA==",
//     FormFilter:
//       devNodeBaseURL +
//       "/FormFilter?code=PLlWaR2bN3Fumsa2Af7gp2LSP88XEfCoKDIfVswXF6pKPVRzMrEs0A==",
//     setStatus:
//       devNodeBaseURL +
//       "/setStatus?code=54rQFZi71y5v2smRz5NimW37EPK5lEum4b7rsOHa1PyXaVJ4V5qgag==",
//     getFiledetails:
//       devNodeBaseURL +
//       "/getFileDetails?code=uVxALZDr/bWIya8OmzzLm0Gr6AjMsd7nIM4mokqSo37pT0TYgbzrjg==",
//     GenerateFEGridCode: devGoBaseURL + "/generatefegridcode",
//     GenerateFEFormCode: devGoBaseURL + "/generatefeformcode",
//     GenerateBECode: devGoBaseURL + "/generatebecode",
//   },
//   phaseAi: {
//     // activeuser : "http://51.8.201.226/activeuser/activeuser",
//     activeuser : "https://npapi.asami.ai/activeuser/activeuser",

//     promptUsageFetch : "https://npapi.asami.ai/promptUsageFetch/promptUsageFetch",

//     fixedResponseFetch : "https://npapi.asami.ai/fixedResponseFetch/fixedResponseFetch",

//     getAsamiUsage : "https://npapi.asami.ai/getAsamiUsage/getAsamiUsage",

//     costAnalysis : "https://npapi.asami.ai/costAnalysis/costAnalysis",
//     // costAnalysis : "http://51.8.201.226/costAnalysis/costAnalysis",
    
//     fetchProjectConfig : "https://npapi.asami.ai/fetchProject/fetchProject",
//     // fetchProject : "http://51.8.201.226/fetchProject/fetchProject",

//     getStory : "https://npapi.asami.ai/getStory/getStory",
//     insertChatinProject:
//     "https://npapi.asami.ai/insertChatID/insertChatID",
//     getTaskConfig : "https://npapi.asami.ai/getTask/getTask",

//     allProjects:"https://qabe-avalon.avasoft.com/allProjects",

//     fetchTask:"https://qabe-avalon.avasoft.com/fetchTask",

//     fetchIssues:"https://qabe-avalon.avasoft.com/fetchIssues",

//     getIssuesConfig : "https://npapi.asami.ai/getIssues/getIssues",

//     functionalDescription: "https://npapi.asami.ai/functionalDescription/functionalDescription",

//     similarityCheck : "https://npapi.asami.ai/similaritySearch/similaritySearch",

//     pushToTFS : "https://npapi.asami.ai/tfsPush/tfsPush",

//     getBlobURL: "https://npapi.asami.ai/getBlobURL/getBlobURL",
//     d2Service : "https://npapi.asami.ai/validate_and_replace/validate_and_replace",

//     krokiService : "https://kroki.io/d2/svg",

//     plantUMLKroki:"https://kroki.io/plantuml/svg",
//     // getImageDocResposne: "https://npapi.asami.ai/getImageVidDocResponse/getImageVidDocResponse",
//     getImageDocResposne: "https://npapi.asami.ai/getImageVidDocResponse/getImageVidDocResponse",

//     insertModelConfig: "https://npapi.asami.ai/insertModelConfig/insertModelConfig",

//     configRoute : "https://npapi.asami.ai/config/config",

//     postTfsConfig: "https://npapi.asami.ai/postTfsConfig/postTfsConfig",

//     configureOrUpdate: "https://npapi.asami.ai/configureOrUpdate/configureOrUpdate",
    
    
//     getRepoandBranch : "https://npapi.asami.ai/getRepoandBranch/getRepoandBranch",
//     // getRepoandBranch : "http://51.8.201.226/getRepoandBranch/getRepoandBranch",

//     getBindParams:
//     "https://npapi.asami.ai/getBindParams/getBindParams",

//     insertGetFeedBack : "https://npapi.asami.ai/insertGetFeedBack/insertGetFeedBack",

//     promptGen : "https://npapi.asami.ai/promptGen/promptGen",

//     systemMessageGen : "https://npapi.asami.ai/systemPrompt/systemPrompt",

//     fetchPromptData : "https://npapi.asami.ai/get_prompt/get_prompt",

//     deletePrompt:
//       "https://npapi.asami.ai/delete_prompt/delete_prompt",
//     getApprovalRequestData:
//       "https://npapi.asami.ai/getApprovalRequestData/getApprovalRequestData",
//     getChat:
//       "https://npapi.asami.ai/getChat/getChat",
//     getChatHistory:
//       "https://npapi.asami.ai/getChatHistory/getChatHistory",
//     getInputTypeAndTechStack:
//       "https://npapi.asami.ai/get_input_type_and_tech_stack/get_input_type_and_tech_stack",
//     getNotifications:
//       "https://npapi.asami.ai/getNotifications/getNotifications",
//     getPromptFieldJson:
//       "https://npapi.asami.ai/getPromptJson/getPromptJson",
//     getTeamHistory:
//       "https://npapi.asami.ai/getTeamHistory/getTeamHistory",
//     insertConnectionID:
//       "https://npapi.asami.ai/insertConnectionID/insertConnectionID",
//     insertPrompt:
//       "https://npapi.asami.ai/insertPrompt/insertPrompt",
//     postMessage:
//       "https://npapi.asami.ai/postMessage/postMessage",
//     securityKeyCheck:
//       "https://npapi.asami.ai/securityKeyCheck/securityKeyCheck",
//     talkToAI:
//       "https://ava-eus-asami-nde-fcn-dev.azurewebsites.net/api/talkToAI?code=CQKMTIltJiswmFpRTg-mNZX58Qo1XZ88kXYv3NXSP7Q4AzFulFqCeg==",
//     updateApproval: "https://npapi.asami.ai/updateApproval/updateApproval",
//     updateNotification: "https://npapi.asami.ai/updateNotification/updateNotification",
//     deleteConnectionID:
//       "https://npapi.asami.ai/deleteConnectionID/deleteConnectionID",
//     insertErrorLogs:
//       "https://npapi.asami.ai/insertErrorLog/insertErrorLog",
//     techstackCRUD:
//       "https://npapi.asami.ai/techstackcrud/techstackcrud",
//     CostCalculationNav:
//       "https://npapi.asami.ai/calculationNav/calculationNav",
//     getPractice:
//       "https://npapi.asami.ai/getPractice/getPractice",

//     insertModelConfiguration:
//       "https://npapi.asami.ai/insertModelConfig/insertModelConfig",
//     getModelConfiguration:"https://npapi.asami.ai/getModelConfiguration/getModelConfiguration",
//     getAllModelConfig:
//       "https://npapi.asami.ai/getAllModelConfig/getAllModelConfig",
//     deleteModelConfiguration:
//       "https://npapi.asami.ai/deleteModelConfiguration/deleteModelConfiguration",
//     serpAPI:
//       "https://npapi.asami.ai/serpAPI/serpAPI",
//     kbUpload:
//       "https://npapi.asami.ai/kbUpload/kbUpload",
//     kbRetriever:
//       "https://npapi.asami.ai/kbRetriever/kbRetriever",
//     getPromptHistory:
//       "https://npapi.asami.ai/getPromptHistory/getPromptHistory",
//       downloadExcel:
//       "https://npapi.asami.ai/PseudocodeCSV/PseudocodeCSV",
//     loadBalancer:
//       "https://npapi.asami.ai/LoadBalancer/LoadBalancer",
//     inputEnhancer :"https://npapi.asami.ai/inputEnhancer/inputEnhancer",
//     stream_loadBalancer:"https://npapi.asami.ai/stream-loadBalancer/stream-loadBalancer"
//   },
//   roleConfig: {
//     LeadershipDL: "54e13cf7-521b-4ca5-a457-06a8208f0027",
//     LeadershipGroup: "53d5f124-34a5-45a7-900a-6402799920dc",
//     LeadGroup: "e7be7b3a-e524-461b-a9b1-15fe6705deff",
//     ScopeID: "api://2214b374-39be-4eec-9ca0-3c6bdac6e02a/CDGEN",
//   },
//   blobBasePath:
//     "https://avaeuscdegennpstgacc.blob.core.windows.net/ava-cdegen-zip-np-con/phase2-zip/",
//   blobInputFilePath:
//     "https://avaeuscdegennpstgacc.blob.core.windows.net/ava-cdegen-inp-np-con/",
//   blobDownloadFilePath:
//     "https://avaeuscdegennpstgacc.blob.core.windows.net/ava-cdegen-zip-np-con",
// };

// export default devConfig;
